:root {
  --header-height: 0; /* calculated on runtime */
  --banner-height: 32px;
  --menu-height: 60px;
  --drawer-margin: var(--header-height);
}

@media (min-width: 1200px) {
  :root {
    --banner-height: 40px;
    --menu-height: 72px;
  }
}

[data-shrunk] {
  --drawer-margin: var(--menu-height);
}
