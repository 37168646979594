@import 'nprogress/nprogress.css';

#nprogress {
  position: relative;
  z-index: 9999;
}

#nprogress .bar {
  background: #ea98b8;
  height: 0.188rem;
}

#nprogress .peg {
  display: none;
}
