.tolstoy-product-tile-price:not(:has(span)) {
  color: black !important; /* Price color */
  font-family: 'Lato', sans-serif !important;
}
.tolstoy-product-tile-price span {
  color: #727272 !important; /* Original price color */
  text-decoration: line-through !important;
  font-family: 'Lato', sans-serif !important;
}
.tolstoy-product-tile-price:has(span) {
  color: #ff0d00 !important; /* Final price color */
  font-family: 'Lato', sans-serif !important;
}
